import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import FadeInWhenVisible from '../../components/fadeInWhenVisible'
import ColumnAlternating from '../../components/columnAlternating'
import Card from '../../components/card'
import Cta from '../../components/cta'

const Industries = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => (
                    <ColumnAlternating
                      key={index}
                      textCol="7"
                      imageCol="5"
                      headingLevel={2}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                      link={sections.btnlink}
                      linktext={sections.linktext}
                      colour={sections.btncolour}
                    />
                  ))}
                </div>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        ) : (
          ''
        )}

        <section className="bg-gray-light">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                {post.frontmatter.indresources.title}
              </h2>
              {post.frontmatter.indresources.indres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.indresources.indres.map((industryres, index) => (
                      <Fragment key={index}>
                        <Card
                          collg="4"
                          colmd="6"
                          height="7.5rem"
                          title={industryres.title}
                          subtitle={industryres.subtitle}
                          description={industryres.description}
                          image={industryres.image.childImageSharp.gatsbyImageData}
                          alt={industryres.alttext}
                          placement={industryres.placement}
                          link={industryres.link}
                          descriptionClass="text-card-small"
                        />
                      </Fragment>
                    ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      </main>
    </Layout>
  )
}
export default Industries

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "industries" } }) {
      frontmatter {
        meta {
          title 
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        ctatext
        ctaslug
        alttext
        openingpara 
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          linktext
          btncolour
        }
        indresources {
          title
          indres {
            title
            subtitle
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            placement
            alttext
            link
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`